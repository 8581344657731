import React from "react";
import "./service.css";
import { servicesInfo, serviceItem } from "../../contents/reference/contents"

export const Services = () => {

    return (<div className="service">

        <h1 className="service_title">{servicesInfo.Title}</h1>
        <hr className="service_hr" />
        <div className="service_content">
            <div>
                <h1 className="service_sub_title">{servicesInfo.SubTitle}</h1>
            </div>

            <div>
                <p className="service_description">{servicesInfo.Description}</p>
                <div>
                    {serviceItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="service_item_title">{data.Title}</h3>
                                <hr className="service_hr"/>
                                <p className="service_item_description">{data.Description}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>


    </div>)
}
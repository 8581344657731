import React from "react";
import "./download.css";
import { downloadInfo } from "../../contents/reference/contents"
import DownloadImage from "../../contents/assets/Globalization.svg"
import PlayLogo from "../../contents/img/google_play_store.png"

export const Download = () => {

    const openPlayStore = () => {

        const packageName = 'com.mahatvapoorn.handbook';
        const playStoreUrl = `https://play.google.com/store/apps/details?id=${packageName}`;
        window.open(playStoreUrl, '_blank');

    };


    return (<div className="download">

        <h1 className="download_title">{downloadInfo.Title}</h1>
        <hr className="download_hr" />
        <div className="download_content">
            <div>
                <img className="download_image" src={DownloadImage} />
            </div>
            <div>
                <h1 className="download_sub_title">{downloadInfo.SubTitle}</h1>
                <p className="download_description">{downloadInfo.Description}</p>
                <img className="download_hand_book_download_btn" src={PlayLogo} onClick={openPlayStore} />            </div>
        </div>
    </div>)
}
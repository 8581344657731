import React from "react";
import "./contactUs.css";
import { contactInfo, generalInquiries, customerSupport, businessInquiries, visitUs, connectwith, mission, feedback } from "../../contents/reference/contents"

export const ContactUs = () => {

    return (<div className="contact">

        <h1 className="contact_title">{contactInfo.TITLE}</h1>
        <hr className="contact_hr" />
        <p className="contact_description">{contactInfo.DESCRIPTION}</p>
        <div className="contact_content">
            <div>
                <h1 className="contact_sub_title">{contactInfo.SUB_TITLE}</h1>
            </div>

            <div>
                <h3>{generalInquiries.Title}</h3>
                <h5>{generalInquiries.Email}</h5>

            </div>

        </div>

        <div className="contact_content">
            <div>
                <h1 className="contact_sub_title">{customerSupport.Title}</h1>
            </div>

            <div>
                <p className="contact_feedbak_descrition">{customerSupport.Description}</p>
                <h5 className="contact_feedbak_email">{customerSupport.Email}</h5>

            </div>

        </div>
        <div className="contact_content">
            <div>
                <h1 className="contact_sub_title">{businessInquiries.Title}</h1>
            </div>

            <div>
                <p className="contact_feedbak_descrition">{businessInquiries.Description}</p>
                <h5 className="contact_feedbak_email">{businessInquiries.Email}</h5>

            </div>

        </div>

        <div className="contact_content">
            <div>
                <h1 className="contact_sub_title">{mission.Title}</h1>
            </div>

            <div>
                <p className="contact_feedbak_descrition">{mission.Description}</p>


            </div>

        </div>
        <div className="contact_content">
            <div>
                <h1 className="contact_sub_title">{feedback.Title}</h1>
            </div>

            <div>
                <p className="contact_feedbak_descrition">{feedback.Description}</p>
                <h5 className="contact_feedbak_email">{feedback.Email}</h5>
                <p className="contact_feedbak_message">{feedback.Message}</p>


            </div>

        </div>

    </div>)
}
const privacyPolicy = {
    Title: "Privacy Policy",
    LastUpdate: "01-24-2024",
    SubTitle: "Welcome to Mahatvapoorn!",
    Description: '"Mahatvapoorn ("we," "us," or "our") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide through our website, www.mahatvapoorn.com (the "Website"). By accessing or using our Website, you agree to the terms of this Privacy Policy."'
}

const informationWeCollect = {
    Title: "1. Information We Collect",
    Description: "We may collect various types of information, including",
}

const informationWeCollectItem = [{
    Title: "1.1 Personal Information: This may include your name, email address, contact information, and other details you voluntarily provide when using our Website."

}, {
    Title: "1.2 Usage Data: We may collect information about your interactions with our Website, such as the pages you visit, the links you click, and other actions you take."
}, {
    Title: "1.3 Device and Browser Information: We may collect information about your device and browser, including your IP address, operating system, and browser type."
}]

const howWeUseYourInformation = {
    Title: "2. How We Use Your Information",
    Description: "We use the collected information for the following purposes:"
}

const howWeUseYourInformationItem = [{
    Title: "2.1 To Provide and Improve Our Services: We use your information to deliver and enhance our products and services."
}, {
    Title: "2.2 Communication: We may use your contact information to send you updates, newsletters, or other communications related to our mission and services. You can opt out of these communications at any time."
}, {
    Title: "2.3 Analytics: We use analytics tools to analyze and understand how users interact with our Website, helping us improve user experience and optimize our services."

}, {
    Title: "2.4 Legal Compliance: We may use your information to comply with applicable laws and regulations."
}]

const cookiesandTrackingTechnologies = {
    Title: "3. Cookies and Tracking Technologies",
    Description: "We use cookies and similar tracking technologies to enhance your experience on our Website. You can manage your cookie preferences through your browser settings."
}

const disclosureOfYourInformation = {
    Title: "4. Disclosure of Your Information",
    Description: "We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy."
}

const security = {
    Title: "5. Security",
    Description: "We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, and destruction."
}
const linksToThirdPartyWebsites = {
    Title: "6. Links to Third-Party Websites",
    Description: "Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. Please review the privacy policies of third-party sites before providing any personal information."
}
const childrenPrivacy = {
    Title: "7. Children's Privacy",
    Description: "Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take steps to delete the information."
}
const changesTothisPrivacyPolicy = {
    Title: "8. Changes to this Privacy Policy",
    Description: '"We may update this Privacy Policy periodically. Any changes will be posted on this page with a revised "Last Updated" date. We encourage you to review this Privacy Policy regularly."'
}
const contactUs = {
    Title: "9. Contact Us",
    Description: "If you have any questions or concerns about this Privacy Policy, please",
    Email: "contact us at contacts@mahatvapoorn.com",
    Message: "Thank you for choosing Mahatvapoorn!"
}
export {
    privacyPolicy,
    informationWeCollect,
    informationWeCollectItem,
    howWeUseYourInformation,
    howWeUseYourInformationItem,
    cookiesandTrackingTechnologies,
    disclosureOfYourInformation,
    security,
    linksToThirdPartyWebsites,
    childrenPrivacy,
    changesTothisPrivacyPolicy,
    contactUs,


}
import React from "react";
import Typewriter from "typewriter-effect";
import "./home.css"
import Logo from "../../contents/assets/logo.svg"
import { websiteInfo, welcomeInfo } from "../../contents/reference/contents"
import { Download } from "../download/download"
import { Services } from "../services/service"
import { WhyIsThis } from "../whyisthis/whyisthis"
import { AboutUs } from "../about/aboutUs"
import { ContactUs } from "../contact/contactUs"

export const Home = () => {
    return (

        <>
            <h1 style={{ display: "flex", justifyContent: "center", marginTop: "50px" }} className="title">{welcomeInfo.Title}</h1>
            <div className="home">
                <div className="contant">
                    <img src={Logo} className="home_logo" />
                </div>

                <div className="contant">
                    <h2 className="type_writer"><Typewriter
                        options={{
                            strings: [
                                welcomeInfo.animated.first,
                                welcomeInfo.animated.second,
                                welcomeInfo.animated.third,
                            ],
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 10,

                        }} /></h2>
                    <p className="website_description">{websiteInfo.Description}</p>

                </div>


            </div>
            <Download />
            <Services />
            <WhyIsThis />
            <AboutUs />
            <ContactUs />
        </>

    )


}
import React from "react";
import { Route, Routes } from "react-router-dom";
import withRouter from "./withRounter"
import { Home } from "../home/home";
import { Download } from "../download/download";
import { Services } from "../services/service";
import { WhyIsThis } from "../whyisthis/whyisthis";
import { AboutUs } from "../about/aboutUs";
import { ContactUs } from "../contact/contactUs";
import { PrivacyPolicy } from "../privacypolicy/privacypolicy";
import { TermsOfService } from "../termsofservice/termsofservice";
import { FAQ } from "../faq/faq";
import { Help } from "../help/help";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AnimatedRoutes = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 400,
        exit: 400,
      }}
      classNames="page"
      unmountOnExit
    >
      <Routes location={location}>
        <Route exact path="/" element={<Home />} />
        <Route path="/download" element={<Download />} />
        <Route path="/services" element={<Services />} />
        <Route path="/whyismahatvapoorn" element={<WhyIsThis />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terns-of-service" element={<TermsOfService />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/help" element={<Help />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </CSSTransition>
  </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedRoutes />
    </div>
  );
}

export default AppRoutes;

import React, { useState } from "react";
import "./footer.css"
import { Link } from "react-router-dom";

export const Footermain = () => {

    const [isActive, setActive] = useState("false");

    const handleToggle = () => {
        setActive(!isActive);
        document.body.classList.toggle("ovhidden");
    };

    return (<footer>

        <div className="footer_box_one">
        <Link onClick={handleToggle} to="/privacy-policy" className="links">Privacy Policy</Link>
            <Link onClick={handleToggle} to="/terns-of-service" className="links">Terms of Service</Link>
        </div>
        <div className="footer_box_two">
            <text>&copy; 2024 Mahatvapoorn. All Rights Reserved. | www.mahatvapoorn.com</text>
        </div>

        <div className="footer_box_three">
            <Link onClick={handleToggle} to="/faq" className="links">FAQ</Link>
            <Link onClick={handleToggle} to="/contact" className="links">Contact Us</Link>
        </div>


    </footer>)
}
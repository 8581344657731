import React from "react";
import "./help.css";
import { helpInfo, ourProductsAndServices, ourProductsAndServicesItem, whyChooseMahatvapoorn, whyChooseMahatvapoornItem, contactUs, connectWithUs, message } from "../../contents/reference/help"

export const Help = () => {

    return (<div className="help">

        <h1 className="help_title">{helpInfo.Title}</h1>
        <hr className="help_hr" />
        <div className="help_content">
            <div>
                <h1 className="help_sub_title">{helpInfo.SubTitle}</h1>
            </div>

            <div>
                <p className="help_description">{helpInfo.Description}</p>
            </div>

        </div>
        <div className="help_content">
            <div>
                <h1 className="help_sub_title">{ourProductsAndServices.Title}</h1>
            </div>

            <div>
                <p className="help_description">{ourProductsAndServices.Description}</p>
                <div>
                    {ourProductsAndServicesItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="help_item_title">{data.Title}</h3>
                                <hr className="help_hr" />
                                <p className="help_item_description">{data.Description}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>

        <div className="help_content">
            <div>
                <h1 className="help_sub_title">{whyChooseMahatvapoorn.Title}</h1>
            </div>

            <div>
                <div>
                    {whyChooseMahatvapoornItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="help_item_title">{data.Title}</h3>
                                <hr className="help_hr" />
                                <p className="help_item_description">{data.Description}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>

        <div className="help_content">
            <div>
                <h1 className="help_sub_title">{contactUs.Title}</h1>
            </div>

            <div>
                <p className="help_description">{contactUs.Description}</p>

            </div>

        </div>

        <p className="help_message">{message.Message}</p>
    </div>)
}
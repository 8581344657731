import React from "react";
import "./faq.css"
import { fAQ, fAQItem } from "../../contents/reference/faq"

export const FAQ = () => {
    return (<div> <h1 className="faq_title">{fAQ.Title}</h1>
        <hr className="faq_hr" />
        <div className="faq_content">
            <div>
                <h1 className="faq_sub_title">{fAQ.SubTitle}</h1>
            </div>

            <div>
                <div>
                    {fAQItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="faq_item_title">{data.Title}</h3>
                                <hr className="faq_hr" />
                                <p className="faq_item_description">{data.Description}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>
        <text className="faq_message">{fAQ.BottomDescrptionOne}</text>
        <text className="faq_message">{fAQ.BottomDescrptionTwo}</text>
    </div>)
}
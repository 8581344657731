const websiteInfo = {
    Logo: "",
    Name: "Mahatvapoorn",
    Domain: "www.mahatvapoorn.com",
    Title: "Everything for Everyone",
    SubTitle: "",
    Definition: "",
    Description: "Mahatvapoorn is a dynamic and forward thinking company dedicated to delivering significant and impactful solutions. As a multifaceted organization, we prioritize the importance of our offerings in various domains. With a commitment to excellence, Mahatvapoorn seeks to create lasting value through innovation, reliability, and a steadfast focus on addressing the most crucial needs of our clients and stakeholders. Visit www.mahatvapoorn.com to explore our comprehensive range of services and discover the meaningful difference we bring to the world.",
    Mission: "Everything for Everyone",
    Desitination: "Everything for Everyone",

}

const welcomeInfo = {
    Asset: "",
    Title: "Welcome to Mahatvapoorn",
    animated: {
        first: "I love coding.",
        second: "Happy journey.",
        third: "I'M Developing Software for World.",
    },
    Description: "We are delighted to welcome you to Mahatvapoorn, where our mission is to provide Everythings for Everyone. As a dynamic and innovative company, we strive to create a diverse and inclusive environment that caters to the needs of every individual.",

}

const downloadInfo = {
    Asset: "",
    Title: "Mahatvapoorn Softwares",
    SubTitle: "Download Mahatvapoorn - HandBook - for manage your business.",
    Description: "Welcome to Mahatvapoorn Handbook, the revolutionary new app designed to transform the way you manage, strategize, and narrate your business journey. As the digital companion for entrepreneurs and business enthusiasts, Mahatvapoorn HandBook brings a suite of powerful tools and features to empower your business narrative.",

}

const servicesInfo = {
    Asset: "",
    Title: "Mahatvapoorn Services",
    SubTitle: "Our Some Services For You ❤",
    Description: "We are delighted to welcome you to Mahatvapoorn, where our mission is to provide Everythings for Everyone. As a dynamic and innovative company, we strive to create a diverse and inclusive environment that caters to the needs of every individual.",

}
const serviceItem = [{
    Title: "E-commerce Platform",
    Description: "A comprehensive online marketplace where customers can find a diverse range of products from various categories, ensuring a one-stop shopping experience for everyone.",
}, {
    Title: "Personalized Services",
    Description: "Tailored solutions to meet the unique needs of individual customers. This could include personalized shopping recommendations, customized products, and personalized assistance.",
}, {
    Title: "Consultancy Services",
    Description: "Expert advice and consultancy services across various domains such as business, technology, and lifestyle, ensuring that everyone can access valuable insights and guidance.",
}, {
    Title: "Education and Training",
    Description: "Offering a variety of courses, workshops, and training programs to empower individuals with knowledge and skills, catering to diverse interests and career paths.",
}, {
    Title: "Health and Wellness",
    Description: "Providing a range of health and wellness services, including fitness programs, mental health support, and personalized wellness plans, to promote a holistic approach to well-being.",
}, {
    Title: "Technology Solutions",
    Description: "Cutting-edge technology solutions for businesses and individuals, encompassing software development, IT consulting, and innovative digital products to meet the evolving needs of a diverse audience.",
}, {
    Title: "Event Management",
    Description: "Planning and executing events for different occasions, ensuring memorable experiences for everyone. This includes corporate events, celebrations, and community gatherings.",
}, {
    Title: "Financial Services",
    Description: "Comprehensive financial services, including investment advice, financial planning, and banking services, to cater to the diverse financial goals and needs of individuals and businesses.",
}, {
    Title: "Social Impact Initiatives",
    Description: "Engaging in philanthropic efforts and social responsibility programs to contribute positively to the community, aligning with the mission of everything for everyone.",
}, {
    Title: "Cultural and Entertainment Services",
    Description: "Curating cultural events, entertainment shows, and creative experiences that appeal to a wide audience, celebrating diversity and inclusivity.",
}, {
    Title: "Travel and Hospitality",
    Description: "Offering travel services, accommodation solutions, and travel packages that cater to the diverse preferences and needs of travelers.",
}, {
    Title: "Virtual and Augmented Reality Experiences",
    Description: "Providing immersive and interactive experiences through virtual and augmented reality technologies, ensuring engaging content and entertainment for a broad audience.",
}]

const whyisthisInfo = {
    Asset: "",
    Title: "Why is Mahatvapoorn",
    SubTitle: "The Significance of Mahatvapoorn for Our New Company",
    Description: "In the dynamic landscape of business, choosing a name that reflects purpose, values, and inclusivity is crucial for establishing a strong foundation. As we embark on this exciting journey with Mahatvapoorn, our new company, it's essential to understand why this name holds immense significance for us and the mission we've set forth."


}

const whyisthisItem = [{
    Title: "Cultural Resonance",
    Description: '"Maha" translates to great or significant, and "tvapoorn" suggests completeness in Hindi. By combining these elements, Mahatvapoorn embodies a sense of greatness and completeness, aligning our brand with cultural richness and resonating with diverse audiences.',

}, {
    Title: "Mission Clarity",
    Description: '"Our mission, succinctly stated as "everything for everyone," encapsulates the essence of Mahatvapoorn. The name serves as a daily reminder of our commitment to providing comprehensive solutions that cater to the needs of everyone, irrespective of their background, preferences, or challenges."',
}, {
    Title: "Brand Identity",
    Description: "In the digital age, a company's online presence is paramount. The domain www.mahatvapoorn.com not only reinforces our brand identity but also provides a memorable and easily accessible platform for our stakeholders to engage with us.",
}, {
    Title: "Inclusivity and Diversity",
    Description: "Mahatvapoorn goes beyond being just a name; it's a reflection of our values. We believe in creating an inclusive environment where diversity is celebrated. The all-encompassing nature of our name reinforces our dedication to serving a wide array of clients and customers.",
}, {
    Title: "Aspirational Vision",
    Description: "Choosing a name like Mahatvapoorn sets the bar high for our aspirations. It challenges us to continually strive for greatness in everything we do, ensuring that our products, services, and interactions reflect the completeness embedded in our brand.",
}, {
    Title: "Market Differentiation",
    Description: "In a competitive market, a distinctive name can set a company apart. Mahatvapoorn not only distinguishes us but also invites curiosity and interest, prompting individuals to explore the meaningful connection between our name and our mission.",
}, {
    Title: "Customer Trust",
    Description: "Mahatvapoorn goes beyond being just a name; it's a reflection of our values. We believe in creating an inclusive environment where diversity is celebrated. The all-encompassing nature of our name reinforces our dedication to serving a wide array of clients and customers.",
}, {
    Title: "",
    Description: "In conclusion, Mahatvapoorn is not just a name; it's a guiding principle that shapes our identity, mission, and aspirations. As we embark on this journey, let's embrace the significance of Mahatvapoorn and work collaboratively to fulfill our commitment of providing everything for everyone."
}]

const aboutInfo = {
    Title: "About Us",
    SubTitle: "Introducing Mahatvapoorn: Everything for Everyone",
    Description: "Welcome to Mahatvapoorn, where our commitment is encapsulated in our very name. Mahatvapoorn, derived from the Sanskrit language, translates to significant or important. This name serves as a testament to our core belief that every individual and every need holds significance. As a newly established company, we are dedicated to providing a diverse range of products and services that cater to the varied needs of everyone.",
    Mission: "Mission: Everything for Everyone",
    MD: "At Mahatvapoorn, our mission is clear: to be the go-to destination for everything, ensuring that no one is left wanting. We understand that the world is made up of unique individuals with diverse tastes, preferences, and requirements. Our goal is to embrace this diversity and offer a comprehensive array of solutions that meet the demands of the widest spectrum of people. www.mahatvapoorn.com: Your Gateway to Everything Our online platform, www.mahatvapoorn.com, is designed to be a seamless and user-friendly space where you can discover, explore, and acquire products and services that align with your distinct needs. From lifestyle and wellness to technology and innovation, Mahatvapoorn aims to be the one-stop destination for all.",
    ValuesthatDefineUs: "Values that Define Us",
    AGlimpseintoOurOfferings: "A Glimpse into Our Offerings",
};


const aboutItem = [{
    Title: "Inclusivity",
    Description: "We believe in serving everyone, regardless of their background, preferences, or requirements. Mahatvapoorn is a brand that celebrates diversity and inclusivity."


}, {
    Title: "Quality Assurance",
    Description: "We are committed to delivering products and services of the highest quality. Each offering under the Mahatvapoorn umbrella undergoes rigorous testing to ensure it meets our stringent standards."


}, {
    Title: "Innovation",
    Description: "The world is dynamic, and so are we. Mahatvapoorn thrives on innovation, constantly exploring new ideas and technologies to bring you cutting-edge solutions."


}, {
    Title: "Customer-Centric Approach",
    Description: "Your satisfaction is our priority. Our customer support team is always ready to assist you, ensuring a hassle-free and enjoyable experience with Mahatvapoorn."


}]


const aboutItem2 = [{
    Title: "Lifestyle Products",
    Description: "Elevate your living with our curated selection of lifestyle products that cater to different tastes and preferences. Wellness Solutions: Prioritize your health and well-being with our range of wellness products and services designed to enhance your overall quality of life."


}, {
    Title: "Wellness Solutions",
    Description: "Prioritize your health and well-being with our range of wellness products and services designed to enhance your overall quality of life."


}, {
    Title: "Technology and Gadgets",
    Description: "Stay ahead in the fast-paced world of technology with our innovative gadgets and cutting-edge tech solutions."


}, {
    Title: "Home and Living Essentials",
    Description: "Discover a wide array of home and living essentials that combine functionality with style, making your space uniquely yours."


}, {
    Title: "",
    Description: "Join us at Mahatvapoorn on our journey to redefine the shopping experience. Whether you're seeking the latest tech trends, wellness solutions, or simply looking to enhance your lifestyle, Mahatvapoorn is here to provide you with everything you need, and more. Your satisfaction is our priority, and your needs are our mission. Mahatvapoorn: Everything for Everyone."


}]


const contactInfo = {
    TITLE: "Contact Us",
    EMAIL: "contacts@mahatvapoorn.com",
    SUB_TITLE: "Contact Information",
    PHONE: "",
    DESCRIPTION: "Thank you for your interest in Mahatvapoorn! We are excited to connect with you. Feel free to reach out to us using the information below.",
};
const generalInquiries = {
    Title: "General Inquiries",
    Email: "contacts@mahatvapoorn.com",
    Phone: "",
}

const customerSupport = {
    Title: "Customer Support",
    Description: "For any assistance or queries related to our products and services, our customer support team is here to help.",
    Email: "contacts@mahatvapoorn.com",
    Phone: "",
}
const businessInquiries = {
    Title: "Business Inquiries",
    Description: "If you are interested in partnering with Mahatvapoorn or have business-related inquiries, please contact our business development team.",
    Email: "contacts@mahatvapoorn.com",
    Phone: "",
}

const visitUs = {
    Title: "Visit Us",
    Description: "We are located at Muzaffarpur, Bihar, India. Feel free to drop by during our business hours."

}
const connectwith = {
    Title: "Connect With Us Online",
    Description: "Stay updated on Mahatvapoorn's latest news, products, and events by following us on social media.",
    FaceBook: "FaceBook",
    Twitter: "Twitter",
    Instagram: "Instagram"


}
const mission = {
    Title: "Mission: Everything for Everyone",
    Description: "At Mahatvapoorn, our mission is to provide everything for everyone. We strive to create products and services that cater to a diverse range of needs, ensuring that everyone can benefit from what we offer. If you share our passion for inclusivity and innovation, we would love to hear from you.",
}

const feedback = {
    Title: "Feedback",
    Description: "We value your feedback. If you have any suggestions, comments, or thoughts you'd like to share with us, please email ",
    Email: "contacts@mahatvapoorn.com",
    Message: "Thank you for choosing Mahatvapoorn. We look forward to connecting with you!"
}


export {
    websiteInfo,
    welcomeInfo,
    downloadInfo,
    servicesInfo,
    serviceItem,
    aboutInfo,
    aboutItem,
    aboutItem2,
    whyisthisInfo,
    whyisthisItem,
    contactInfo,
    generalInquiries,
    customerSupport,
    businessInquiries,
    visitUs,
    connectwith,
    mission,
    feedback
};

// For inquiries, support, or collaboration opportunities, please don't hesitate to get in touch with us. We value your feedback and are here to assist you.
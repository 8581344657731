import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import withRouter from "./components/pages/hooks/withRounter";
import AppRoutes from "./components/pages/hooks/routes"
import { Headermain } from "./components/pages/header/header";
import { Footermain } from "./components/pages/footer/footer";
import "./App.css";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

export default function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop>
        <Headermain />
        <AppRoutes/>
        <Footermain />
      </ScrollToTop>
    </Router>
  );
}

import React from "react";
import "./aboutUs.css";
import { aboutInfo, aboutItem, aboutItem2 } from "../../contents/reference/contents"

export const AboutUs = () => {

    return (<div className="about">

        <h1 className="about_title">{aboutInfo.Title}</h1>
        <hr className="about_hr" />
        <div className="about_content">
            <div>
                <h1 className="about_sub_title">{aboutInfo.SubTitle}</h1>
            </div>

            <div>
                <p className="about_description">{aboutInfo.Description}</p>
            </div>

        </div>

        <div className="about_content">
            <div>
                <h1 className="about_sub_title">{aboutInfo.Mission}</h1>
            </div>

            <div>
                <p className="about_description">{aboutInfo.MD}</p>
            </div>
        </div>

        <div className="about_content">
            <div>
                <h1 className="about_sub_title">{aboutInfo.ValuesthatDefineUs}</h1>
            </div>

            <div>
                    {aboutItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="about_item_title">{data.Title}</h3>
                                <hr className="about_hr" />
                                <p className="about_item_description">{data.Description}</p>
                            </div>
                        );
                    })}

            </div>

        </div>

        <div className="about_content">
            <div>
                <h1 className="about_sub_title">{aboutInfo.AGlimpseintoOurOfferings}</h1>
            </div>

            <div>
                    {aboutItem2.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="about_item_title">{data.Title}</h3>
                                <hr className="about_hr" />
                                <p className="about_item_description">{data.Description}</p>
                            </div>
                        );
                    })}

            </div>

        </div>


    </div>)
}
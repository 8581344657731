import React from "react";
import "./termsofservice.css"
import { termsOfService, termsOfServiceItem, message } from "../../contents/reference/termsOfService"

export const TermsOfService = () => {
    return (<div> <h1 className="terms_of_service_title">{termsOfService.Title}</h1>
        <hr className="terms_of_service_hr" />

        <div className="terms_of_service_content">
            <div>
                <p className="terms_of_service_description" style={{ margin: "15px" }}>{termsOfService.Description}</p>
                <h3 style={{ margin: "15px" }}>Last Update - {termsOfService.LastUpdate}</h3>
            </div>

            <div>
                <div>
                    {termsOfServiceItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="terms_of_service_item_title">{data.Title}</h3>
                                <hr className="terms_of_service_hr" />
                                <p className="terms_of_service_item_description">{data.Description}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>
        <h3 className="privacy_policy_message">{message.message}</h3>
    </div>)
}
const fAQ = {
    Title: "Mahatvapoorn: Everything for Everyone ",
    SubTitle: "Frequently Asked Questions (FAQ)",
    BottomDescrptionOne:"Yes, Mahatvapoorn is committed to social responsibility. We actively participate in philanthropic activities and community outreach programs as part of our mission to make a positive impact on society.",
    BottomDescrptionTwo:"For any additional inquiries or specific concerns, feel free to reach out to our customer support team through the contact information provided on our website. Mahatvapoorn values your trust and is here to serve you with excellence."
}

const fAQItem = [{
    Title: "1. What is Mahatvapoorn?",
    Description: '"Mahatvapoorn is a dynamic and inclusive company committed to providing a wide range of products and services to cater to the diverse needs of everyone. Our mission is encapsulated in our name, which means "important" or "significant" in Hindi, emphasizing the importance we place on serving all individuals."'
}, {
    Title: '2. What does "Everything for Everyone" mean?"',
    Description: '""Everything for Everyone" is our guiding mission statement. It signifies our dedication to offering a comprehensive array of products and services that meet the varied requirements of people from different walks of life."'
}, {
    Title: "3. How can I contact Mahatvapoorn?",
    Description: '"You can reach out to us through our website www.mahatvapoorn.com. Visit our "Contact Us" page for detailed information, including email addresses, phone numbers, and a contact form for your convenience."'
}, {
    Title: "4. What types of products and services does Mahatvapoorn offer?",
    Description: "Mahatvapoorn provides a diverse range of products and services spanning various categories. This includes but is not limited to consumer goods, technology, healthcare, education, and lifestyle products."
}, {
    Title: "5. Is Mahatvapoorn an international company?",
    Description: "Yes, Mahatvapoorn operates globally to ensure that our offerings are accessible to people from different parts of the world. We are committed to promoting inclusivity on a global scale."
}, {
    Title: "6. How does Mahatvapoorn prioritize sustainability?",
    Description: "Sustainability is a core value at Mahatvapoorn. We strive to incorporate eco-friendly practices in our operations, from sourcing products to packaging and distribution. Our commitment to sustainability aligns with our goal of making a positive impact on the planet."
}, {
    Title: "7. Can I trust the quality of Mahatvapoorn products?",
    Description: "Absolutely. Mahatvapoorn is dedicated to maintaining high standards of quality across all our products and services. We work with reputable suppliers and manufacturers to ensure that our customers receive reliable and top-notch offerings."
}, {
    Title: "8. Are there any special promotions or discounts available?",
    Description: "Yes, Mahatvapoorn regularly offers special promotions, discounts, and loyalty programs. Stay updated by visiting our website or subscribing to our newsletter for exclusive deals."
}, {
    Title: "9. How can I stay informed about Mahatvapoorn's latest offerings?",
    Description: "To stay informed about our latest products, services, and promotions, you can follow us on social media platforms, subscribe to our newsletter on the website, or check our blog for updates."
}, {
    Title: "10. Is Mahatvapoorn involved in any philanthropic activities?",
    Description: "Yes, Mahatvapoorn is committed to social responsibility. We actively participate in philanthropic activities and community outreach programs as part of our mission to make a positive impact on society. For any additional inquiries or specific concerns, feel free to reach out to our customer support team through the contact information provided on our website. Mahatvapoorn values your trust and is here to serve you with excellence."
}]

export {
    fAQ,
    fAQItem
}
const helpInfo = {
    Title: "Help",
    SubTitle: "Welcome to Mahatvapoorn - Everything for Everyone!",
    Description: `At Mahatvapoorn, we're dedicated to providing a diverse range of products and services to meet the needs of everyone. Our mission is simple yet profound: "Everything for Everyone." We understand that each individual is unique, and our goal is to offer solutions that cater to the varied preferences and requirements of our customers."`

}
const ourProductsAndServices = {
    Title: "Our Products and Services",
    Description: "Explore our extensive catalog that spans a wide array of categories, ensuring there's something for everyone. From cutting-edge technology to lifestyle essentials, we've curated a collection that reflects the diversity of our global customer base."
}

const ourProductsAndServicesItem = [{
    Title: "Technology Solutions",
    Description: "Stay ahead with our latest tech offerings, from gadgets to software solutions."
}, {
    Title: "Home and Living",
    Description: "Transform your living space with our home decor, furniture, and lifestyle products."
}, {
    Title: "Fashion and Accessories",
    Description: "Express your unique style with our trendy fashion collections and accessories."
}, {
    Title: "Wellness and Health",
    Description: "Prioritize your well-being with our health and wellness products."
}, {
    Title: "Customizable Options",
    Description: "Tailor products to your liking with our customization options."
}]

const whyChooseMahatvapoorn = {
    Title: "Why Choose Mahatvapoorn?",
}
const whyChooseMahatvapoornItem = [{
    Title: "Diverse Selection",
    Description: "Our vast range ensures there's something for everyone, catering to diverse tastes and preferences."
}, {
    Title: "Quality Assurance",
    Description: "We prioritize quality to ensure that every product meets or exceeds your expectations."
}, {
    Title: "Customer-Centric Approach",
    Description: "Your satisfaction is our priority. Our customer support team is ready to assist you with any inquiries or concerns."
}, {
    Title: "Innovation and Trends",
    Description: "Stay updated with the latest trends and innovations through our regularly updated catalog."
}, {
    Title: "Global Accessibility",
    Description: '"Mahatvapoorn is accessible worldwide, bringing our mission of "Everything for Everyone" to customers around the globe."'
}]

const contactUs = {
    Title: "Contact Us",
    Description: "Have questions or need assistance? Reach out to our customer support team via email at support@mahatvapoorn.com.",
}

const connectWithUs = {
    Title: "Connect with Us",
    Description: "Stay connected with Mahatvapoorn on social media to get the latest updates, promotions, and more",
    Facebook: "Facebook",
    Instagram: "Instagram",
    Twitter: "Twitter"

}

const message = {
    Message: "Thank you for choosing Mahatvapoorn - where we strive to make everything accessible for everyone. Your satisfaction is our success!"
}

export {
    helpInfo,
    ourProductsAndServices,
    ourProductsAndServicesItem,
    whyChooseMahatvapoorn,
    whyChooseMahatvapoornItem,
    contactUs,
    connectWithUs,
    message,
}
import React from "react";
import "./whyisthis.css";
import { whyisthisInfo, whyisthisItem } from "../../contents/reference/contents"

export const WhyIsThis = () => {

    return (<div className="whyisthis">

        <h1 className="whyisthis_title">{whyisthisInfo.Title}</h1>
        <hr className="whyisthis_hr" />
        <div className="whyisthis_content">
            <div>
                <h1 className="whyisthis_sub_title">{whyisthisInfo.SubTitle}</h1>
            </div>

            <div>
                <p className="whyisthis_description">{whyisthisInfo.Description}</p>
                <div>
                    {whyisthisItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="whyisthis_item_title">{data.Title}</h3>
                                <hr className="whyisthis_hr"/>
                                <p className="whyisthis_item_description">{data.Description}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>


    </div>)
}
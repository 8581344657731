import React from "react";
import "./privacypolicy.css"
import {
    privacyPolicy,
    informationWeCollect,
    informationWeCollectItem,
    howWeUseYourInformation,
    howWeUseYourInformationItem,
    cookiesandTrackingTechnologies,
    disclosureOfYourInformation,
    security,
    linksToThirdPartyWebsites,
    childrenPrivacy,
    changesTothisPrivacyPolicy,
    contactUs,
} from "../../contents/reference/privacyPolicy"

export const PrivacyPolicy = () => {
    return (<div className="privacy_policy">

        <h1 className="privacy_policy_title">{privacyPolicy.Title}</h1>
        <hr className="privacy_policy_hr" />
        <h1 className="privacy_policy_sub_title" style={{ margin: "15px" }}>{privacyPolicy.SubTitle}</h1>
        <p className="privacy_policy_description" style={{ margin: "15px" }}>{privacyPolicy.Description}</p>
        <h3 style={{ margin: "15px" }}>Last Update - {privacyPolicy.LastUpdate}</h3>
        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{informationWeCollect.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{informationWeCollect.Description}</p>
                <div>
                    {informationWeCollectItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="privacy_policy_item_title">{data.Title}</h3>
                                <hr className="privacy_policy_hr" />
                                <p className="privacy_policy_item_description">{data.Description}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>

        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{howWeUseYourInformation.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{howWeUseYourInformation.Description}</p>
                <div>
                    {howWeUseYourInformationItem.map((data, i) => {
                        return (
                            <div key={i}>
                                <h3 className="privacy_policy_item_title">{data.Title}</h3>
                                <hr className="privacy_policy_hr" />
                                <p className="privacy_policy_item_description">{data.Description}</p>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>

        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{cookiesandTrackingTechnologies.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{cookiesandTrackingTechnologies.Description}</p>

            </div>

        </div>

        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{disclosureOfYourInformation.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{disclosureOfYourInformation.Description}</p>

            </div>

        </div>

        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{security.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{security.Description}</p>

            </div>

        </div>

        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{linksToThirdPartyWebsites.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{linksToThirdPartyWebsites.Description}</p>

            </div>

        </div>

        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{childrenPrivacy.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{childrenPrivacy.Description}</p>

            </div>

        </div>

        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{changesTothisPrivacyPolicy.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{changesTothisPrivacyPolicy.Description}</p>

            </div>

        </div>

        <div className="privacy_policy_content">
            <div>
                <h1 className="privacy_policy_sub_title">{contactUs.Title}</h1>
            </div>

            <div>
                <p className="privacy_policy_description">{contactUs.Description}</p>
                <h3>{contactUs.Email}</h3>

            </div>

        </div>
        <h3 className="privacy_policy_message">{contactUs.Message}</h3>
    </div>)
}
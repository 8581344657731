const termsOfService = {
    Title: "Mahatvapoorn Terms of Service",
    LastUpdate: "01-24-2024",
    Description: '"Welcome to Mahatvapoorn! These Terms of Service ("Terms") govern your use of the Mahatvapoorn website located at www.mahatvapoorn.com (the "Site") and any related services provided by Mahatvapoorn (collectively, the "Services"). By accessing or using the Services, you agree to be bound by these Terms."',
}

const termsOfServiceItem = [{
    Title: "1. Acceptance of Terms",
    Description: "By using the Services, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Services."
}, {
    Title: "2. Mission Statement",
    Description: "Our mission at Mahatvapoorn is to provide everything for everyone. We are committed to creating a platform that serves the diverse needs of individuals, offering a wide range of services and resources to enhance the lives of our users."
}, {
    Title: "3. User Accounts",
    Description: "To access certain features of the Services, you may be required to create a user account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete."
}, {
    Title: "4. Privacy",
    Description: "Your privacy is important to us. Please review our Privacy Policy [link to Privacy Policy] to understand how we collect, use, and disclose information about you."
}, {
    Title: "5. Use of Services",
    Description: '"You agree to use the Services in accordance with all applicable laws and regulations. You also agree not to a. Violate any intellectual property rights or other rights of Mahatvapoorn or any third party. b. Transmit any viruses, worms, or other malicious code. c. Engage in any activity that interferes with or disrupts the Services."'
}, {
    Title: "6. Content",
    Description: "You are solely responsible for the content you submit or display on the Services. By submitting content, you grant Mahatvapoorn a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content."
}, {
    Title: "7. Termination",
    Description: "Mahatvapoorn reserves the right to suspend or terminate your access to the Services at any time, for any reason, without notice."
}, {
    Title: "8. Changes to Terms",
    Description: "Mahatvapoorn reserves the right to modify or update these Terms at any time. We will notify you of any changes by posting the updated Terms on the Site. Your continued use of the Services after the effective date of the updated Terms constitutes your acceptance of the changes."
}, {

    Title: "9. Contact Information",
    Description: "If you have any questions or concerns about these Terms, please contact us at [contacts@mahatvapoorn.com]."
}
]

const message={
    message:"Thank you for choosing Mahatvapoorn!"
}
export {
    termsOfService,
    termsOfServiceItem,
    message
}